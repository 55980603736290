<template>
	<div class="box">
		<ul>
			<li v-for="(item, index) in date" :key="index">
				<div style="display: flex;justify-content: space-between;">
					<div class="rounddot">
						<div class="round"></div>
						<a class="roundtitle" @click="jump(item.id)">
							{{ item.title }}
						</a>
					</div>
					<span class="time">[{{ item.publishTime | format }}]</span>
				</div>
			</li>
		</ul>
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="12" :pageSize="12"></pagination>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	components: {},
	data() {
		return {
			total: 0,
			date: [],
			pageNum: 1,
			pageSize: 12,
		}
	},
	created() {
		this.getArticleListById()
	},
	methods: {
		currentchange(e) {
			this.pageNum = e
			this.getArticleListById()
		},
		getArticleListById() {
			let data = {
				id: this.$route.query.navid,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
			}
			this.$api.getArticleListById(data).then(res => {
				this.total = res.data.data.total
				this.date = res.data.data.list

				this.date.forEach(item => {
					item.title = item.title.replace(/<br>/g, ' ')
				})
			})
		},
		jump(id) {
			let routeData = this.$router.resolve({
				path: '/internationalEn/details',
				query: {
					subjectid: id,
					navindex: this.$route.query.navindex,
					id: this.$route.query.id,
					refresh: new Date().getTime()
				}
			});
			window.open(routeData.href, '_blank');
		},
	}
}
</script>

<style scoped lang="scss">
.box {
	width: 12rem;
	margin: 0 auto;
	// height: 1200px;
	// background: url('../../../../assets/eventtheme/schedule2.png');
	// background-repeat: no-repeat;
	// background-size: contain;
	// margin: 77px auto 0;
}
ul{
	margin-top: 0.7rem;
}

.paging {
	width: 12rem;
	display: flex;
	justify-content: center;
	padding-bottom: 0.73rem;

}
.rounddot {
		display: flex;
		align-items: center;
		font-size: 0.24rem;

	}

	.round {
		width: 0.09rem;
		height: 0.09rem;
		background: #000000;
		border-radius: 50%;
		margin-right: 0.22rem;
	}

	.roundtitle {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 9rem;
		cursor: pointer;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		line-height: 0.5rem;
	}
	.time{
		font-size: 0.24rem;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		opacity: 0.54;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>