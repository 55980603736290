<template>
	<div>

		<div class="box">
			<div class="hezi_box" v-for="(item,index) in date" :key="index" @click="getpurchase(item)">
				<div class="hezi">
					<img :src="item.img" alt="">
				</div>
				<div class="name">{{ item.title }} <span v-if="item.display == 1">{{ item.type == 0?'(境内购票)' : '(境外购票)' }}</span> </div>
			</div>
		</div>
		
		<!-- <div class="biaot">购票指南</div>
		<div class="xian-box">
			<div class="xian"></div>
		</div>
		
		<div class="guide-box">
			<div class="guide">
				<img src="../../../../assets/eventtheme/status7.png" alt="" class="logo">
				<div class="nr-box" @click="getJump(1)">
					<div class="name">项目详情</div>
					<img src="../../../../assets/eventtheme/return.png" alt="" class="teturn">
				</div>
			</div>
			<div class="guide">
				<img src="../../../../assets/eventtheme/status6.png" alt="" class="logo logo1" style="width: 69.2px;height: 69.2px;">
				<div class="nr-box nr-box1" @click="getJump(2)">
					<div class="name">购票须知</div>
					<img src="../../../../assets/eventtheme/return.png" alt="" class="teturn">
				</div>
			</div>
			<div class="guide">
				<img src="../../../../assets/eventtheme/status5.png" alt="" class="logo logo2" style="width: 73px;height: 67.6px;">
				<div class="nr-box nr-box1" @click="getJump(3)">
					<div class="name name1" >帮助</div>
					<img src="../../../../assets/eventtheme/return.png" alt="" class="teturn">
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				date:[]
			}
		},
		created() {
			this.ticketBanner()
		},
		methods: {
			ticketBanner(){
				this.$api.ticketBanner(this.$route.query.id).then(res=>{
					this.date = res.data.data
				})
			},
			// getJump(e){
			// 	if(e == 1){
			// 		for(let i in this.date){
			// 			if(this.date[i].title.trim() == '项目详情'){
			// 				let routeData = this.$router.resolve({
			// 					path: '/internationalEn/details',
			// 					query: {
			// 						subjectid: this.date[i].id,
			// 						logo: this.$route.query.logo,
			// 						navindex: this.$route.query.navindex,
			// 						id:this.$route.query.id,
			// 						refresh: new Date().getTime()
			// 					}
			// 				});
			// 				window.open(routeData.href, '_blank');
			// 			}
			// 		}
			// 	}else if(e == 2){
			// 		for(let i in this.date){
			// 			if(this.date[i].title.trim() == '购票须知'){
			// 				let routeData = this.$router.resolve({
			// 					path: '/internationalEn/details',
			// 					query: {
			// 						subjectid: this.date[i].id,
			// 						logo: this.$route.query.logo,
			// 						navindex: this.$route.query.navindex,
			// 						id:this.$route.query.id,
			// 						refresh: new Date().getTime()
			// 					}
			// 				});
			// 				window.open(routeData.href, '_blank');
			// 			}
			// 		}
			// 	}else{
			// 		window.open('https://help.damai.cn/helpPage.htm','_blank')
			// 	}
			// },
			getpurchase(e){
				window.open(e.url, '_blank');
			}
		}
	}
</script>

<style scoped lang="scss">
	.box{
		width: 12.9rem;
		display: flex;
		flex-wrap: wrap;
		padding-top: 0.66rem;
		margin: 0 auto;
		cursor: pointer;
		.hezi_box{
			width: 4rem;
			margin-right: 0.44rem;
			text-align: center;
			// display: flex;
			// align-items: center;
			.hezi{
				width: 4rem;
				height: 2.52rem;
				background: #FFFFFF;
				box-shadow: 0rem 0rem 0rem 0rem rgba(122,166,205,0.11);
				border-radius: 0.29rem;
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		.name{
			font-size: 0.25rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			line-height: 0.38rem;
			margin-top: 0.33rem;
			margin-bottom: 0.38rem;
			span{
				color:#52B5FF;
			}
		}
	}

	.hezi_box:nth-child(3n) {
		margin-right: 0;
	}
	
	.biaot{
		font-size: 38px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		width: 1200px;
		margin: 87px auto 0;
		text-align: center;
	}
	
	.xian-box{
		width: 1200px;
		margin: 29px auto 0;
		display: flex;
		justify-content: center;
		.xian{
			width: 83px;
			height: 8px;
			background: #0091FF;
			border-radius: 4px;
		}
	}
	
	.guide-box{
		display: flex;
		width: 1200px;
		margin: 103px auto 0;
		padding-bottom: 61px;
		.guide{
			width: 400px;
			height:261px ;
			background: url('../../../../assets/eventtheme/hbback6.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			
			.logo{
				width: 59.4px;
				height: 63.1px;
				margin-top: 48px;
				margin-left: 170px;
			}
			.logo1{
				width: 69.2px !important;
				height: 69.2px !important;
			}
			.logo2{
				width: 73.4px !important;
				height: 67.6px !important;
			}
			.nr-box{
				display: flex;
				align-items: center;
				margin-top: 35px;
				cursor: pointer;
				.name{
					font-size: 35px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-left: 115px;
				}
				.name1{
					margin-left: 140px !important;
				}
				.teturn{
					width: 18.7px;
					height: 26px;
					margin-left: 42px;
					margin-top: 5px;
				}
			}
			.nr-box1{
				margin-top: 30px !important;
			}
		}
	}
	
</style>