<template>
	<div>
		<div style="padding: 50px 0;width: 1300px;margin: 0 auto;">
			<div v-html="datedan.content || ''"></div>
			<div v-if="datedan.contentFileUrl && JSON.parse(datedan.contentFileUrl).length>0">
			
			  <ul>
			    <li v-for="(item,index) in JSON.parse(datedan.contentFileUrl)" :key="index" style="margin-bottom: 10PX"><a :href="item.url" style="color: #2d52a0">
			      <img src="/images/fujian.png" alt="" style="width: 20PX">
			      {{item.name}}
			    </a></li>
			  </ul>
			</div>
		</div>
		<!-- <div class="box" v-for="(item,index) in date" :key="index">
			<div class="box-reg" v-if="item.imgUrl">
				<div class="img">
					<img :src="item.imgUrl" alt="" class="logo">
				</div>
				<div class="wz">
					<div class="title" @click="jump(item.id)">{{item.title}}</div>
					<div class="brief" @click="jump(item.id)" v-html="getText(item.content)"></div>
					<div class="brief-box">
						<div class="time">[{{item.publishTime | format}}]</div>
						<div class="button" @click="jump(item.id)">
							查看详情
							<img src="../../../../assets/eventtheme/more.png" alt="">
						</div>
					</div>
				</div>
			</div>

			<div class="dandu" v-else>
				<div class="title" @click="jump(item.id)">{{item.title}}</div>
				<div class="brief" @click="jump(item.id)" v-html="getText(item.content)"></div>
				<div class="brief-box">
					<div class="time">[{{item.publishTime | format}}]
						<div class="button" @click="jump(item.id)">
							查看详情
							<img src="../../../../assets/eventtheme/more.png" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="10" :pageSize="10"></pagination>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				total: 0,
				date: [],
				pageNum: 1,
				pageSize: 10,
				datedan:{},
			}
		},
		created() {
			this.getArticleById()
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			getArticleById(){
				this.$api.getArticleById({id:this.$route.query.navid}).then(res=>{
					this.datedan = res.data.data
				})
			},
			currentchange(e) {
				this.pageNum = e
				this.getArticleListById()
			},
			getArticleListById() {
				let data = {
					id: this.$route.query.navid,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
				}
				this.$api.getArticleListById(data).then(res => {
					this.total = res.data.data.total
					this.date = res.data.data.list
					
					this.date.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			getText(str) {
				const regExp = /<[^>]+>/g; // 匹配所有HTML标签
				const plainText = str.replace(regExp, ''); // 去除所有标签
				return plainText
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/internationalEn/details',
					query: {
						subjectid: id,
						logo: this.$route.query.logo,
						navindex: this.$route.query.navindex,
						id:this.$route.query.id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
		}
	}
</script>

<style scoped lang="scss">
	.box {
		width: 1315px;
		margin: 98px auto 0;

		.box-reg {
			display: flex;
			margin-bottom: 87px;

			.img{
				min-width: 590px;
				max-width: 590px;
				height: 375px;
				overflow: hidden;
				
				img {
					width: 100%;
					height: 100%;
				}
				
				img:hover {
					transform: scale(1.15);
				}
			}

			.wz {
				min-width: 679px;
				margin-left: 46px;

				.title {
					font-size: 33px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 50px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					cursor: pointer;
				}
				
				.title:hover{
					color: #0091FF;
				}

				.brief {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 42px;
					margin-top: 39px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					cursor: pointer;
					text-indent: 40px;
				}

				.brief-box {
					display: flex;
					justify-content: space-between;
					margin-top: 92px;
					align-items: center;

					.time {
						font-size: 21px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #7D7D7D;
					}

					.button {
						width: 250px;
						height: 47px;
						background: #F8FDFF;
						border: 1px solid #000000;
						border-radius: 28px;
						font-size: 19px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;

						img {
							width: 30px;
							height: 13px;
							margin-left: 15px;
						}
					}
				}
			}
		}

		.dandu {
			margin-bottom: 87px;

			.title {
				font-size: 33px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 50px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
			
			.title:hover{
				color: #0091FF;
			}

			.brief {
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 42px;
				margin-top: 39px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				cursor: pointer;
				text-indent: 40px;
			}

			.brief-box {
				display: flex;
				justify-content: space-between;
				margin-top: 51px;
				align-items: flex-start;

				.time {
					font-size: 21px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #7D7D7D;
					margin-top: 17px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 1315px;
				}

				.button {
					width: 250px;
					height: 47px;
					background: #F8FDFF;
					border: 1px solid #000000;
					border-radius: 28px;
					font-size: 19px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					img {
						width: 30px;
						height: 13px;
						margin-left: 15px;
					}
				}
			}
		}
	}

	.paging {
		width: 1200px;
		display: flex;
		justify-content: center;
		padding-bottom: 73px;
		margin: 0 auto;
	}
	
</style>