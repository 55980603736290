<template>
	<div>
		<div class="box">
			<!-- <div class="box-reg">
				<img src="https://lanhu.oss-cn-beijing.aliyuncs.com/psdc5mji2h49mfp5n5zlcad9vce9zvjaj5s33e5076f-97f0-4b89-b040-a8635fc21628"
					alt="">
				<div class="wz">
					<div class="title">2023年世界花样滑冰大奖赛总决赛在京举行为期3天，进入倒计时</div>
					<div class="brief">为有序推进亚奥理事会大家庭总部饭店交通服务保障筹办工作，从8月11日至8月14日，大家庭总部饭店交通团队组织开展了第一阶段交通保障演练。</div>
					<div class="brief-box">
						<div class="time"> [2023-12-08]</div>
						<div class="button">
							MORE
							<img src="../../../../assets/eventtheme/more.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="box-reg">
				<img src="https://lanhu.oss-cn-beijing.aliyuncs.com/psdc5mji2h49mfp5n5zlcad9vce9zvjaj5s33e5076f-97f0-4b89-b040-a8635fc21628"
					alt="">
				<div class="wz">
					<div class="title">2023年世界花样滑冰大奖赛总决赛在京举行为期3天，进入倒计时</div>
					<div class="brief">为有序推进亚奥理事会大家庭总部饭店交通服务保障筹办工作，从8月11日至8月14日，大家庭总部饭店交通团队组织开展了第一阶段交通保障演练。</div>
					<div class="brief-box">
						<div class="time"> [2023-12-08]</div>
						<div class="button">
							MORE
							<img src="../../../../assets/eventtheme/more.png" alt="">
						</div>
					</div>
				</div>
			</div> -->
			
			<div class="dandu" v-for="(item,index) in date" :key="index" >
				<div class="title" @click="jump(item.id)">{{item.title}}</div>
				<div class="brief" @click="jump(item.id)" v-html="getText(item.content)"></div>
				<div class="brief-box">
					<div class="time">[{{item.publishTime | format}}]</div>
					<div class="button" @click="jump(item.id)">
						MORE
						<img src="../../../../assets/eventtheme/more.png" alt="">
					</div>
				</div>
			</div>
			
		</div>
		
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="8" :pageSize="8"></pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				total:0,
				date:[],
				pageNum: 1,
				pageSize: 8,
			}
		},
		created() {
			this.getArticleListById()
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			currentchange(e){
				this.pageNum = e
				this.getArticleListById()
			},
			getArticleListById() {
				let data = {
					id: this.$route.query.navid,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
				}
				this.$api.getArticleListById(data).then(res => {
					this.total = res.data.data.total
					this.date = res.data.data.list
					
					this.date.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			getText(str) {
				const regExp = /<[^>]+>/g; // 匹配所有HTML标签
				const plainText = str.replace(regExp, ''); // 去除所有标签
				return plainText
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/internationalEn/details',
					query: {
						subjectid: id,
						logo: this.$route.query.logo,
						navindex: this.$route.query.navindex,
						id:this.$route.query.id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
		}
	}
</script>

<style scoped lang="scss">
	.box {
		width: 1200px;
		margin: 98px auto 0;

		.box-reg {
			display: flex;
			margin-bottom: 87px;

			img {
				width: 590px;
				height: 375px;
			}

			.wz {
				margin-left: 36px;

				.title {
					font-size: 33px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 50px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					cursor: pointer;
				}
				
				.title:hover{
					color: #0091FF;
				}

				.brief {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 42px;
					margin-top: 39px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					cursor: pointer;
					text-indent: 40px;
				}

				.brief-box {
					display: flex;
					justify-content: space-between;
					margin-top: 92px;
					align-items: flex-start;

					.time {
						font-size: 21px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #7D7D7D;
						margin-top: 17px;
					}

					.button {
						width: 250px;
						height: 47px;
						background: #F8FDFF;
						border: 1px solid #000000;
						border-radius: 28px;
						font-size: 19px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;

						img {
							width: 30px;
							height: 13px;
							margin-left: 15px;
						}
					}
				}
			}
		}
		.dandu{
			width: 1200px;
			margin: 0 auto;
			margin-bottom: 87px;
			.title {
				font-size: 33px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 50px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				cursor: pointer;
			}
			.title:hover{
				color: #0091FF;
			}
			
			.brief {
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 42px;
				margin-top: 39px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				cursor: pointer;
				text-indent: 40px;
			}
			
			.brief-box {
				display: flex;
				justify-content: space-between;
				margin-top: 51px;
				align-items: flex-start;
			
				.time {
					font-size: 21px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #7D7D7D;
					margin-top: 17px;
				}
			
				.button {
					width: 250px;
					height: 47px;
					background: #F8FDFF;
					border: 1px solid #000000;
					border-radius: 28px;
					font-size: 19px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
			
					img {
						width: 30px;
						height: 13px;
						margin-left: 15px;
					}
				}
			}
		}
	}
	
	.paging {
		width: 1200px;
		display: flex;
		justify-content: center;
		padding-bottom: 73px;
		margin: 0 auto;
	}
</style>